import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as ActionsCart from '../../redux/actions/cart';
import { CartSymbol } from '../../enum/cartSymbol';
import { CartActions } from '../../redux/actions';
import { I18n } from 'react-redux-i18n';
import RenderCondition from '../no-visual/renderCondition';


const ProductCheckoutPayment = (props) => {
  const isSelected = !!props.products.find(p => p.id === props.product);

  //This useEffect runs twice, adding the product with a quantity of 2.
  useEffect(() => {
    props.addProductsInCart(props.product);
  }, []);

  //This useEffect runs every time there is a change in the array of products.
  //In other words, after the above useEffect adds the product for the second time,
  //we run the logic below to remove the extra product, always leaving the product with a quantity of 1.
  useEffect(() => {
    const p = props.products.find(p => p.id === props.product);

    if (p && p.quantity > 1) {
      props.removeProductFromCart(props.product);
    }
  }, [ props, props.products ]);

  const addOrRemoveToCart = () => {
    if (isSelected) {
      props.removeProductFromCart(props.product);
    } else {
      props.addProductsInCart(props.product);
    }
  };

  return (
    <>
      <RenderCondition condition={props.products.length > 1}>
        <div className={`checkout-chocosono ${props.finished && !props.recurrent && 'checkout-chocosono--disabled'}`}>
          <div className="checkout-chocosono__container">
            <div className="checkout-chocosono__container__input">
              <input
                id="chocosono_radio"
                type="radio"
                onClick={addOrRemoveToCart}
                checked={isSelected}
              />
            </div>
            <div className="checkout-chocosono__container__img">
              <img
                className="checkout-chocosono__container__img__image"
                src='/assets/img/product-variants/vitagummy-promotion.png'
              />
            </div>
            <div className="checkout-chocosono__container__label">
              <span className="checkout-chocosono__container__label__tag">
                {I18n.t('CHECKOUT.VITA_GUMMY_CARD.TAG')}
              </span>
              <p className="checkout-chocosono__container__label__text">
                {I18n.t('CHECKOUT.VITA_GUMMY_CARD.TEXT')}<br />
                <p className="checkout-chocosono__container__label__text__bold">
                  {I18n.t('CHECKOUT.VITA_GUMMY_CARD.TEXT_BOLD')}{' '}
                </p>
                <p className="checkout-chocosono__container__label__text__bold__value">
                  {I18n.t('CHECKOUT.VITA_GUMMY_CARD.VALUE')}
                </p>
              </p>
              <p className="checkout-chocosono__container__label__sign">
                {I18n.t('CHECKOUT.VITA_GUMMY_CARD.SIGN')}
              </p>
            </div>
          </div>
        </div>
      </RenderCondition>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  products: state.cart.products || [],
});

const mapDispatchToProps = (dispatch) => ({
  addProductsInCart: (idProduct) => dispatch(ActionsCart.addOrRemoveProduct(idProduct, 1, CartSymbol.ADD)),
  removeProductFromCart: (id, quantity) => dispatch(CartActions.addOrRemoveProduct(id, quantity, CartSymbol.REMOVE)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCheckoutPayment);