import React from 'react';
import { getProductByCode } from '../../services/product';
import { translate } from '../../services/i18n';
import ProductList from '../../enum/productList';
import { connect } from 'react-redux';
import { ProductModalActions } from '../../redux/actions';
import { useHistory } from 'react-router-dom';

const BestSeller = ({ addProductInCart, soldOff, setVisibleModal }) => {
  const history = useHistory();

  return (
    <>
      <div className="best-seller-header">
        <h3 className="best-seller-header__title">
          {translate('COMPONENTS.BEST_SELLER.TITLE')}
        </h3>
      </div>
      <div className="best-seller">
        <div className="best-seller__item">
          <div
            className="best-seller__item__img-container"
            onClick={() => history.push('/produtos/tonico-para-barba')}
          >
            <img
              className="best-seller__item__img-container__img mobile-hidden"
              src="/assets/img/best-seller/barbudo.png"
              alt=""
            />
            <img
              className="best-seller__item__img-container__img mobile-visible"
              src="/assets/img/best-seller/barbudo-mobile.png"
              alt=""
            />
          </div>
          <div
            className="best-seller__item__ghost-button"
            onClick={async () => {
              await addProductInCart(
                getProductByCode(ProductList.COMBO_BARBUDO),
              );
              setVisibleModal(true);
            }}
          ></div>
        </div>
        <div className="best-seller__item">
          <div
            className="best-seller__item__img-container"
            onClick={() => history.push('/produtos/gummy')}
          >
            <img
              className="best-seller__item__img-container__img mobile-hidden"
              src="/assets/img/best-seller/combo-gummy.png"
              alt=""
            />
            <img
              className="best-seller__item__img-container__img mobile-visible"
              src="/assets/img/best-seller/combo-gummy-mobile.png"
              alt=""
            />
          </div>
          <div
            className="best-seller__item__ghost-button"
            onClick={async () => {
              await addProductInCart(getProductByCode(ProductList.COMBO_GUMMY));
              setVisibleModal(true);
            }}
          ></div>
        </div>
        <div className="best-seller__item">
          <div
            className="best-seller__item__img-container"
            onClick={() => {
              window.open('/avaliacao?startIn=2', '_blank');
            }}
          >
            <img
              className="best-seller__item__img-container__img mobile-hidden"
              src="/assets/img/best-seller/sleep-gummy.png"
              alt=""
            />
            <img
              className="best-seller__item__img-container__img mobile-visible"
              src="/assets/img/best-seller/sleep-gummy-mobile.png"
              alt=""
            />
          </div>
          <div
            className="best-seller__item__ghost-button"
            onClick={() => {
              window.open('/avaliacao?startIn=2', '_blank');
            }}
          ></div>
        </div>
        <div className="best-seller__item">
          <div
            className="best-seller__item__img-container"
            onClick={() => {
              window.open('/avaliacao?startIn=2', '_blank');
            }}
          >
            <img
              className="best-seller__item__img-container__img mobile-hidden"
              src="/assets/img/best-seller/combo-sleep-gummy.png"
              alt=""
            />
            <img
              className="best-seller__item__img-container__img mobile-visible"
              src="/assets/img/best-seller/combo-sleep-gummy-mobile.png"
              alt=""
            />
          </div>
          <div
            className="best-seller__item__ghost-button"
            onClick={() => {
              window.open('/avaliacao?startIn=2', '_blank');
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isVisible: state.productModal,
});

const mapDispatchToProps = (dispatch) => ({
  setVisibleModal: () => dispatch(ProductModalActions.showModalProduct()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BestSeller);
