import { message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svg/arrow-footer.svg';
import CreditCards from '../../assets/png/credi_cards.png';
import IconEmail from '../../assets/svg/ic_email.svg';
import IconFacebook from '../../assets/svg/ic_facebook.svg';
import IconInstagram from '../../assets/svg/ic_instagram.svg';
import IconLinkedin from '../../assets/svg/ic_linkedin.svg';
import IconWhatsapp from '../../assets/svg/ic_whatsapp.svg';
import * as ActionProducts from '../../redux/actions/products';
import * as AuthActions from '../../redux/actions/auth';
import { translate } from '../../services/i18n';
import PageFooterMobile from './PageFooterMobile';
import { LINK_TYPES_MAP } from '../../enum/footer';

const PageFooter = ({ getProductByID, newsLetter, removeEmailFooter }) => {

  const getProduct = async (id) => {
    await getProductByID(id);
    document.querySelector('body').scrollTo(0, 0);
  };

  const [ clientName, setClientName ] = useState(0);
  const [ clientEmail, setClientEmail ] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (clientEmail.trim() === '' || clientEmail.length < 6) return message.error(translate('ERRORS.newsLetter.email'));

    const payload = {
      name: clientName,
      email: clientEmail,
    };

    await newsLetter(payload, () => message.success(translate('PAGES.FOOTER.FORM.SUCCESS')));
  };

  return (
    <>
      <div>
        {!removeEmailFooter && (
          <div className="footer__top">
            <h2 className="footer__top__title">
              {translate('PAGES.FOOTER.TITLE')}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="footer__top__form">
                <input
                  className="footer__top__form__input"
                  name="email"
                  type="email"
                  placeholder={translate('PAGES.FOOTER.FORM.EMAIL')}
                  value={(clientEmail && clientEmail.toLowerCase()) || ''}
                  required
                  onChange={(e) => setClientEmail(e.target.value)}
                />
                <button
                  className="footer__top__form__button"
                  value={translate('PAGES.FOOTER.FORM.BUTTON')}
                  type="submit"
                >
                  <Arrow />
                </button>
              </div>
            </form>
            <p className="footer__top__text-politic">
              {translate('PAGES.FOOTER.POLITIC')}
              <a href={translate('PAGES.FOOTER.LINKS.ITEMS_RIGHT.SIX.LINK')}>
                {translate('PAGES.FOOTER.POLITIC_LINK')}
              </a>
            </p>
          </div>
        )}
        < div className="footer__bottom mobile-hidden">
          <div className="footer__bottom__links">
            <div className="footer__bottom__links__medical">
              <a className="footer__bottom__links__medical__link"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={translate('PAGES.FOOTER.LINKS.MEDICAL.LINK')}
                rel="noreferrer">
                {translate('PAGES.FOOTER.LINKS.MEDICAL.TEXT')}
              </a>
            </div>
            <div className="footer__bottom__links__products">
              <h3 className="footer__bottom__links__products__title">
                {translate('PAGES.FOOTER.LINKS.TITLE_ONE')}
              </h3>
              {Object.values(translate('PAGES.FOOTER.LINKS.ITEMS_LEFT')).map((item, itemKey) => (
                <a
                  key={itemKey}
                  href={item.LINK}
                  onClick={item.ID ? () => getProduct(item.ID) : null}
                  className="footer__bottom__links__products__link"
                >
                  <p className="footer__bottom__links__products__link__text">{item.NAME}</p>
                </a>
              ))}
            </div>
            <div className="footer__bottom__links__institucional">
              <h3 className="footer__bottom__links__institucional__title">
                {translate('PAGES.FOOTER.LINKS.TITLE_TWO')}
              </h3>
              {Object.values(translate('PAGES.FOOTER.LINKS.ITEMS_RIGHT')).map((item, itemKey) => (
                <div key={itemKey}>
                  {Object.keys(LINK_TYPES_MAP).includes(item.NAME) &&
                    <a
                      href={item.LINK}
                      rel="noopener noreferrer"
                      className="footer__bottom__links__institucional__link"
                      target="_blank"
                    >
                      <p>{item.NAME}</p>
                    </a>
                  }
                </div>
              ))}
            </div>
            <div className="footer__bottom__links__aux">
              <div className="footer__bottom__links__aux__social">
                <h3 className="footer__bottom__links__aux__social__title">
                  {translate('PAGES.FOOTER.SOCIALS.TITLE')}
                </h3>
                <div className="footer__bottom__links__aux__social__icons">
                  <a
                    href="https://www.facebook.com/themensbr"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={IconFacebook}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://instagram.com/themens_oficial"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={IconInstagram}
                      alt=""
                    />
                  </a>
                  <a
                    className="footer__bottom__links__aux__social__icons--last"
                    href="https://www.linkedin.com/company/themens"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={IconLinkedin}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="footer__bottom__links__aux__talk">
                <h3 className="footer__bottom__links__aux__talk__title">
                  {translate('PAGES.FOOTER.SOCIALS.TITLE_TALK')}
                </h3>
                <div className="footer__bottom__links__aux__talk__icons">
                  <a
                    href="mailto:contato@themens.com.br"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <img
                      src={IconEmail}
                      alt=""
                    />
                  </a>
                  <a
                    className="footer__bottom__links__aux__talk__icons--last"
                    href="https://api.whatsapp.com/send?phone=5511942120797"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <img
                      src={IconWhatsapp}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="footer__bottom__links__aux__payment">
                <h3 className="footer__bottom__links__aux__payment__title">
                  {translate('PAGES.FOOTER.SOCIALS.TITLE_PAYMENT')}
                </h3>
                <div>
                  <img
                    className="footer__bottom__links__aux__payment__cards"
                    src={CreditCards}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom__info">
            <p className="footer__bottom__info__text">
              {translate('PAGES.FOOTER.RODAPE.TEXT')}
            </p>
          </div>
          <div className="footer__bottom__rights">
            <p className="footer__bottom__rights__text">
              {translate('PAGES.FOOTER.RODAPE.RIGHTS')}
            </p>
          </div>
        </div >
        <script data-cfasync="false"
          async="true"
          id="cartstack"
          src="https://app.cartstack.com.br/activeAPI/load.js"
          type="text/javascript"></script>
        <div className="mobile-visible">
          <PageFooterMobile getProductByID={getProductByID} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  productByID: state.product.productByID,
});

const mapDispatchToProps = (dispatch) => ({
  getProductByID: (id) => dispatch(ActionProducts.getProductByID(id)),
  newsLetter: (data, callback) => dispatch(AuthActions.newsLetter(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageFooter);
