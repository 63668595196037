import React, { useRef } from 'react';
import OurProductsCard from '../OurProductsCard/OurProductsCard';
import Slider from 'react-slick';

const OurProducts = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 5,
    vertical: false,
    verticalSwiping: false,
    horizontal: true,
    horizontalSwiping: true,
    autoplay: false,
    centerMode: true,
    centerPadding: '27%'
  };
  // eslint-disable-next-line no-unused-vars
  let sliderRef = useRef();

  return (
    <>
      <div className="our-products mobile-hidden">
        <div className="our-products__title">
          Nossos produtos
        </div>
        <div className="our-products__container">
          <OurProductsCard
            name='Vita Gummy'
            price='R$66,30'
            productId='2ad58d0e-5934-4f1a-9d51-8cba378122b6'
            backgroundImage='/assets/img/our-products/vita-gummy-fundo-produto.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Tônico para barba'
            price='R$64,90'
            productId='fbd7397f-8071-4f0a-9a64-90debfbf908a'
            backgroundImage='/assets/img/our-products/Rectangle.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Creatina 500g 100% Pura'
            price='R$189,90'
            productId='29609206-a390-4b73-8213-8464c4c4ea4a'
            backgroundImage='/assets/img/our-products/creatina-fundo.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Shampoo Energizante'
            price='R$44,90'
            productId='e6c99cac-19cf-4f18-8704-0fff17dbb133'
            backgroundImage='/assets/img/our-products/shampoo-fundo-produto.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Anti-aging com VIT C'
            price='R$189,90'
            productId='8cc5dd9a-732f-45cb-bfd1-2dbdbd462719'
            backgroundImage='/assets/img/our-products/anti-aging-fundo-produto.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Chocosono - Zero Açucar 5 caixas'
            price='R$59,90'
            productId='3c1ca3a0-8bb4-4419-aac8-ef2e1ed3c136'
            backgroundImage='/assets/img/our-products/chocosono-fundo.jpg'
            message={props.message}
          />
          <OurProductsCard
            name='Sleep Gummy'
            price='R$99,90'
            productId='e743ec60-df88-4f4b-ac84-748be2473d2b'
            backgroundImage='/assets/img/our-products/sleep-gummy-fundo-produto.jpg'
            label
            message={props.message}
            redirectToPrescription={() => {
              window.open('/avaliacao?startIn=2', '_blank');
            }}
          />
        </div>
      </div>
      <div className="our-products mobile-visible">
        <div className="our-products__title">
          Nossos produtos
        </div>
        <div className="our-products__container">
          <Slider ref={slider => (sliderRef = slider)}
            {...settings}>
            <OurProductsCard
              name='Vita Gummy'
              price='R$66,30'
              productId='2ad58d0e-5934-4f1a-9d51-8cba378122b6'
              backgroundImage='/assets/img/our-products/vita-gummy-fundo-produto.jpg'
              message={props.message}
            />
            <OurProductsCard
              name='Tônico para barba'
              price='R$64,90'
              productId='fbd7397f-8071-4f0a-9a64-90debfbf908a'
              backgroundImage='/assets/img/our-products/Rectangle.jpg'
              message={props.message}
            />
            <OurProductsCard
              name='Sleep Gummy'
              price='R$79,90'
              productId='e743ec60-df88-4f4b-ac84-748be2473d2b'
              backgroundImage='/assets/img/our-products/sleep-gummy-fundo-produto.jpg'
              message={props.message}
              label
            />
            <OurProductsCard
              name='Shampoo Energizante'
              price='R$44,90'
              productId='e6c99cac-19cf-4f18-8704-0fff17dbb133'
              backgroundImage='/assets/img/our-products/shampoo-fundo-produto.jpg'
              message={props.message}
            />
            <OurProductsCard
              name='Anti-aging com VIT C'
              price='R$189,90'
              productId='8cc5dd9a-732f-45cb-bfd1-2dbdbd462719'
              backgroundImage='/assets/img/our-products/anti-aging-fundo-produto.jpg'
              message={props.message}
            />
            <OurProductsCard
              name='Chocosono - Zero Açucar 5 caixas'
              price='R$59,90'
              productId='3c1ca3a0-8bb4-4419-aac8-ef2e1ed3c136'
              backgroundImage='/assets/img/our-products/chocosono-fundo.jpg'
              message={props.message}
            />
            <OurProductsCard
              name='Creatina 500g 100% Pura'
              price='R$189,90'
              productId='29609206-a390-4b73-8213-8464c4c4ea4a'
              backgroundImage='/assets/img/our-products/creatina-fundo.jpg'
              message={props.message}
            />
          </Slider>
        </div>
      </div>
    </>
  );
};

export default OurProducts;